import lazySizes from 'lazysizes';
import alpine from "alpinejs";
import axios from "axios";
import Swup from "swup";
import SwupScrollPlugin from "@swup/scroll-plugin";
import SwupProgressPlugin from '@swup/progress-plugin';
import SwupFadeTheme from "@swup/fade-theme";
import SwupGtmPlugin from '@swup/gtm-plugin';

window.lazySizes = lazySizes;

export default (configuration={}) => {

    let isCMS = !!document.querySelector("html.cms");
    let isWebsite = !!document.querySelector("html.website");

    window.hasAcceptedCookies = function(){
        return new Promise((resolve, reject) => {
            axios.get("/has-accepted-cookies/").then(resolve).catch(reject);
        });
    };

    if (isWebsite){
        let configurationSwup = {
            containers: ["#page"],
            plugins: [
                new SwupScrollPlugin({
                    // doScrollingRightAway: true,
                    scrollFriction: 0.4,
                    scrollAcceleration: 0.2,

                    ...(configuration.SwupScrollPlugin ? configuration.SwupScrollPlugin : {}),
                }),
                new SwupGtmPlugin(),

                new SwupProgressPlugin({
                    delay: 800
                }),
            ],

            ...(configuration.swup ? configuration.swup : {}),
        };

        if (typeof configuration.swupDisableFadePlugin == "undefined" || configuration.swupDisableFadePlugin == false){
            configurationSwup.plugins.push(new SwupFadeTheme({
                mainElement: configurationSwup.containers[0], // needs SWUP container. Likely "#page" but some websites may have a different container named.
            }),);
        }

        window.swup = new Swup(configurationSwup);
    }

    return new Promise((resolve, reject) => {
        resolve({
            isCMS,
            isWebsite,
            swup: window.swup,
        });
    });
}
