import Blueprint from "$blueprint/js/blueprint";
import Reveal from "$blueprint/js/reveal/index";
import Forms from "$blueprint/js/forms/index";
import Swiper from "$blueprint/js/swiper/index";

import * as InfiniteScroll from "infinite-scroll"

import Axios from "axios";

window.axios = Axios;

const blueprintConfiguration = {
    swup: {
        containers: ["#swup"],
    },
};

Blueprint(blueprintConfiguration).then(blueprint => {

    // show fickity on CMS and website. Only setup SWUP on website.
    Swiper.create().then(() => {
        if (blueprint.isWebsite){
            Swiper.swup(blueprint.swup);
        }
    });

    if (blueprint.isWebsite){

        const options = {
            root: null,
            rootMargin: "-160px 0px -80px 0px", // let the web visitor see animations within viewport

            /**
             * @type {array}
             */
            threshold: (() => { // setup trigger points
                let thresholds = [];
                const steps = 20;

                for (let index = 1.0; index < steps; index++){
                    thresholds.push(index / steps);
                }

                thresholds.push(0);

                return thresholds;
            })()
        };

        /**
         * Init our page observer. This looks for elements coming in and out of view.
         * @type {IntersectionObserver}
         */
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.intersectionRatio > 0.05){
                    entry.target.classList.add("--is-visible");
                }
                else if (!entry.target.classList.contains("animate-once")) {
                    entry.target.classList.remove("--is-visible");
                }
            })
        }, options);

        // https://infinite-scroll.com/
        window.InfiniteScroll = InfiniteScroll

        Reveal.create().then(Reveal.swup(blueprint.swup));
        Forms.create().then(Forms.swup(blueprint.swup));

        // Tag all cloaked elements on first page load.
        document.body.querySelectorAll('[x-cloak]').forEach((node) => {
            node.setAttribute('data-alpine-was-cloaked', '');
        })

        const initAlpine = () => {
            window.Alpine.discoverUninitializedComponents((el) => {
                window.Alpine.initializeComponent(el);
            });

            requestAnimationFrame(() => { window.Alpine.pauseMutationObserver = false });

            /**
             * Lets add our `.animation-*` classes to the observer.
             */
            document.querySelectorAll("[class*=animation-]").forEach(element => {
                observer.observe(element);
            });
        };

        const beforeRenderAlpine = (event) => {
            const newBody = document.body;
            newBody.querySelectorAll('[data-alpine-generated-me],[x-cloak]').forEach((el) => {
                if (el.hasAttribute('x-cloak')) {
                    // When we get a new document body tag any cloaked elements so we can cloak
                    // them again before caching.
                    el.setAttribute('data-alpine-was-cloaked', '')
                }

                if (el.hasAttribute('data-alpine-generated-me')) {
                    el.removeAttribute('data-alpine-generated-me')
                    if (typeof el.__x_for_key === 'undefined' && typeof el.__x_inserted_me === 'undefined') {
                        el.remove()
                    }
                }
            })
        };

        const beforeCacheAlpine = () => {
            window.Alpine.pauseMutationObserver = true;

            document.body.querySelectorAll('[x-for],[x-if],[data-alpine-was-cloaked]').forEach((el) => {
                // Cloak any elements again that were tagged when the page was loaded
                if (el.hasAttribute('data-alpine-was-cloaked')) {
                    el.setAttribute('x-cloak', '')
                    el.removeAttribute('data-alpine-was-cloaked')
                }

                if (el.hasAttribute('x-for')) {
                    let nextEl = el.nextElementSibling
                    while (nextEl && typeof nextEl.__x_for_key !== 'undefined') {
                        const currEl = nextEl
                        nextEl = nextEl.nextElementSibling
                        currEl.setAttribute('data-alpine-generated-me', true)
                    }
                } else if (el.hasAttribute('x-if')) {
                    const ifEl = el.nextElementSibling
                    if (ifEl && typeof ifEl.__x_inserted_me !== 'undefined') {
                        ifEl.setAttribute('data-alpine-generated-me', true)
                    }
                }
            })
        };



        document.addEventListener("lazyloaded", event => {
            Swiper.instances.forEach(instance => {
                instance.swiper.updateSize();
            });
        });

        blueprint.swup.on("clickLink", (event) => {

            // If the clicked link is in our scroll position cache, clear it.
            // We only want this cache used when user is paginating through their history
            // (i.e. back and forward history buttons)
            try {
                const delegatePathname = event.delegateTarget.pathname;

                if (swupPagesScrollPositionCache[delegatePathname]){
                    delete swupPagesScrollPositionCache[delegatePathname];
                }
            } catch (error){
                console.log("swup clickLink failed to find deleteTarget pathname of URL. Error: ", error);
            }

            // Cache the scroll position of page we're leaving, so if user goes back they don't
            // have to scroll back down.
            swupPagesScrollPositionCache[window.location.pathname] = scroll.scroll.instance.scroll.y;
        });

        initAlpine();

        blueprint.swup.on("contentReplaced", () => {
            beforeRenderAlpine();
            beforeCacheAlpine();
            initAlpine();
        });

        blueprint.swup.on("popState", event => {
            initAlpine();
        });

        const contactUrl = window.location.origin + "/contact-us";
        const styles = "font-size: 1.25rem; color: #3c766a;";
        // rickroll image
        console.log("%c  ", "background-image: url(https://i.imgur.com/G5wIFwD.gif); background-size: 8rem;background-repeat:no-repeat;font-size: 8rem;");
        console.log("%cHello there keen developer. We're often looking for new talent, why not get in touch with us? Attach code '#i_saw_the_console'. 👋 \n" + contactUrl + "\n", styles);
    }
});
