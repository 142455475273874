import Axios from "axios";

export default {
    instances: [],

    create(selector = ".form-nimble"){
        return new Promise((resolve, reject) => {
            try {
                this.destroy().then(() => {
                    document.querySelectorAll(selector).forEach(element => {

                        const constElement = element;

                        const instance = {
                            element: constElement,
                            handler(event){
                                event.preventDefault();

                                var form = event.target;

                                var ajax = {
                                    url: form.getAttribute("action"),
                                    method: form.getAttribute("method"),
                                    data: new FormData(form),
                                };

                                var alpine = constElement.__x;
                                var alpine$data = alpine ? alpine.$data : null;

                                if (alpine$data){
                                    if (alpine$data.isProcessing === true){
                                        return;
                                    }
                                    alpine$data.isProcessing = true;
                                }

                                Axios(ajax).then(response => {
                                    form.dispatchEvent(new CustomEvent("status", {detail: "success"}));

                                }).catch(error => {
                                    if (error.response && error.response.status == 422){
                                        form.dispatchEvent(new CustomEvent("errors", {detail: error.response.data}));
                                        form.dispatchEvent(new CustomEvent("status", {detail: "fail"}));
                                    }
                                }).finally(() => {
                                    if (alpine$data){
                                        alpine$data.isProcessing = false;
                                    }
                                });
                            },
                            handlerEventName: "submit",
                        };

                        instance.element.addEventListener(instance.handlerEventName, instance.handler);
                        this.instances.push(instance);
                    });
                });
            }
            catch (error){
                console.error(error);
                reject(error);
                return;
            }
            resolve();
        });
    },

    destroy(){
        return new Promise((resolve, reject) => {
            try {
                this.instances.forEach(instance => {
                    instance.element.removeEventListener(instance.handlerEventName, instance.handler);
                });
                this.instances = [];
            }
            catch (error){
                console.error(error);
                reject(error);
                return;
            }
            resolve();
        });
    },

    /**
     * swup()
     * Detect swup events and correctly restart animation detection
     * @param  {Object} swup
     * @return {Promise}
     */
    swup(swup){
        return new Promise((resolve, reject) => {
            try {
                swup.on("willReplaceContent", event => {
                    this.destroy();
                });
                swup.on("contentReplaced", event => {
                    this.create();
                });
                resolve();
            } catch (error){
                console.error(error);
                reject(error);
            }
        });
    },
}
