import Swiper from 'swiper/bundle';

// Looks weird? You probably need to import Swiper's styles.
// In your CSS:
// ```
// @import "swiper/swiper.min.css";
// ```

const handler = {
    instances: [],
    /**
     * Mapping for advanced slider elements to configurations (stop bloating views with JS)
     *
     * Example JS file:
     * ```
     * Swiper.map = {
     *   hero: {...swiperConfiguration},
     * };
     * ```
     * Example Blade View file:
     * ```
     * <div data-swiper="hero">
    *    ...
     * </div>
     *
     * @type {JSON}
     */
    map: {},

    create()
    {
        return new Promise((resolve, reject) => {
            this.destroy().then(() => {
                try {

                    document.querySelectorAll("[data-swiper]").forEach(element => {

                        let swiper = element.swiper;

                        if (swiper == null){

                            let config = JSON.parse(element.getAttribute("data-swiper"));

                            // if it's a mapping, fetch mapped configuration
                            if (typeof config == "string"){
                                config = this.map[config];
                            }

                            swiper = new Swiper(element, config);
                        }

                        this.instances.push({
                            element: element,
                            swiper: swiper,
                        });
                    });

                } catch (error){
                    console.error(error);
                    reject(error);
                }

                resolve();
            }).catch(reject);
        });
    },

    getInstance(element)
    {
        element = typeof element == "string" ? document.querySelector(element) : element;
        return this.instances.find(instance => instance.element === element);
    },

    /**
     * Destroy()
     * @return {Promise}
     */
    destroy()
    {
        return new Promise((resolve, reject) => {

            try {

                this.instances.forEach(element => {
                    element.swiper.destroy(true, false);
                });

            } catch (error){
                console.error(error);
                reject(error);
            }

            this.instances = [];

            resolve();
        });
    },

    /**
     * swup()
     * Detect swup events and correctly restart animation detection
     * @param  {Object} swup
     * @return {Promise}
     */
    swup(swup)
    {
        return new Promise((resolve, reject) => {
            try {
                swup.on("willReplaceContent", event => {
                    this.destroy();
                });
                swup.on("contentReplaced", event => {
                    this.create();
                });
                resolve();
            } catch (error){
                console.error(error);
                reject(error);
            }
        });
    },
};

window.SwiperHandler = handler;

export default handler;
